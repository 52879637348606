var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"wraper",staticClass:"input-field-widget-container",style:(_vm.setStyles)},[((_vm.fieldType)==='checkbox'&&(_vm.configs && _vm.configs.fieldType !== 'text'))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fieldValue[_vm.placeholder]),expression:"fieldValue[placeholder]"}],staticClass:"field input-field-item",class:{
      'red-border': _vm.errorsList[_vm.widgetIdx],
      'with-value': _vm.fieldValue[_vm.placeholder],
    },attrs:{"placeholder":_vm.placeholder,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.fieldValue[_vm.placeholder])?_vm._i(_vm.fieldValue[_vm.placeholder],null)>-1:(_vm.fieldValue[_vm.placeholder])},on:{"input":_vm.onInput,"change":function($event){var $$a=_vm.fieldValue[_vm.placeholder],$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.fieldValue, _vm.placeholder, $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.fieldValue, _vm.placeholder, $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.fieldValue, _vm.placeholder, $$c)}}}}):((_vm.fieldType)==='radio'&&(_vm.configs && _vm.configs.fieldType !== 'text'))?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fieldValue[_vm.placeholder]),expression:"fieldValue[placeholder]"}],staticClass:"field input-field-item",class:{
      'red-border': _vm.errorsList[_vm.widgetIdx],
      'with-value': _vm.fieldValue[_vm.placeholder],
    },attrs:{"placeholder":_vm.placeholder,"type":"radio"},domProps:{"checked":_vm._q(_vm.fieldValue[_vm.placeholder],null)},on:{"input":_vm.onInput,"change":function($event){return _vm.$set(_vm.fieldValue, _vm.placeholder, null)}}}):(_vm.configs && _vm.configs.fieldType !== 'text')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.fieldValue[_vm.placeholder]),expression:"fieldValue[placeholder]"}],staticClass:"field input-field-item",class:{
      'red-border': _vm.errorsList[_vm.widgetIdx],
      'with-value': _vm.fieldValue[_vm.placeholder],
    },attrs:{"placeholder":_vm.placeholder,"type":_vm.fieldType},domProps:{"value":(_vm.fieldValue[_vm.placeholder])},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.fieldValue, _vm.placeholder, $event.target.value)},_vm.onInput]}}):_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.fieldValue[_vm.placeholder]),expression:"fieldValue[placeholder]"}],staticClass:"field input-field-textarea",class:{ 'red-border': _vm.errorsList[_vm.widgetIdx] },attrs:{"placeholder":_vm.placeholder},domProps:{"value":(_vm.fieldValue[_vm.placeholder])},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.fieldValue, _vm.placeholder, $event.target.value)},_vm.onInput]}}),_c('div',{staticClass:"error"},[_vm._v(_vm._s(_vm.errorsList[_vm.widgetIdx]))])])
}
var staticRenderFns = []

export { render, staticRenderFns }