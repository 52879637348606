<template>
  <div class="input-field-widget-container" ref="wraper" :style="setStyles">
    <input
      v-if="configs && configs.fieldType !== 'text'"
      class="field input-field-item"
      :placeholder="placeholder"
      v-model="fieldValue[placeholder]"
      @input="onInput"
      :type="fieldType"
      :class="{
        'red-border': errorsList[widgetIdx],
        'with-value': fieldValue[placeholder],
      }"
    />
    <textarea
      v-else
      class="field input-field-textarea"
      v-model="fieldValue[placeholder]"
      :placeholder="placeholder"
      @input="onInput"
      :class="{ 'red-border': errorsList[widgetIdx] }"
    ></textarea>
    <div class="error">{{ errorsList[widgetIdx] }}</div>
  </div>
</template>

<script>
import setStyles from "@/utils/setStyles";
import fetchFont from "@/utils/fetchFont";
import { mapGetters } from "vuex";
import { updateRespond } from "@/utils/clickAction";

export default {
  name: "SvInputFieldWidget",
  props: ["widgetIdx"],
  computed: {
    ...mapGetters([
      "interaction",
      "errorsList",
      "step",
      "respond",
      "activeStep",
    ]),
    widget() {
      return this.activeStep?.objects?.[this.widgetIdx];
    },
    configs() {
      return this.widget.configs;
    },
    fieldsData() {
      if (!this.widget) return {};
      return this.widget.configs.fieldsDataList.filter((f) => f.visible);
    },
    textItem() {
      return this.widget.objects[1];
    },
    backgoundItem() {
      return this.widget.objects[0];
    },
    placeholder() {
      return this.textItem.text;
    },
    setStyles() {
      return {
        "--field-background-color": this.backgoundItem.fill,
        "--field-text-color": this.textItem.fill,
        "--textAlign": this.textItem.textAlign,
        "--fontSize": this.textItem.fontSize,
        "--fontWeight": this.textItem.fontWeight,
        "--fontStyle": this.textItem.fontStyle,
        "--textDecoration": this.textItem.underline ? "underline" : "none",
        "--fontFamily": this.textItem.fontFamily.replace(/\.(ttf|otf)/g, ""),
        "--borderRadius": this.backgoundItem.rx + "px",
        "--padding": (this.backgoundItem.rx || 6) / 2 + "px",
        "--width":
          this.backgoundItem.width - (this.backgoundItem.rx || 6) - 2 + "px",
        "--height":
          this.backgoundItem.height - (this.backgoundItem.rx || 6) - 2 + "px",
        ...setStyles(this.widget),
      };
    },
  },
  data() {
    return {
      fieldValue: {},
      errorMsg: "",
      timeoutId: null,
      fieldType: "text",
    };
  },
  mounted() {
    this.fieldType = this.configs.fieldType;
    // this.fieldType =
    //   this.configs.fieldType === "date"
    //     ? "text"
    //     : this.configs.fieldType ?? "text";
    const fontFamily = this.textItem.fontFamily.replace(/\.(ttf|otf)/g, "");
    const fontLink = this.widget.configs.fontLink;

    if (fontFamily)
      fetchFont(fontLink, fontFamily).then(() => {
        this.$refs.wraper.style.fontFamily = fontFamily;
        this.$emit("hideLoader");
      });
  },
  methods: {
    // onFocus(e) {
    //   if (this.configs.fieldType === "date") e.target.type = "date";
    // },
    // onBlur(e) {
    //   if (
    //     !this.fieldValue[this.placeholder] &&
    //     this.configs.fieldType === "date"
    //   )
    //     e.target.type = "text";
    // },
    onInput() {
      this.$store.dispatch("updateRespond", {
        data: {
          widgetType: "input field",
          widgetName: "input field",
          actionType: "input field",
          actionData: this.fieldValue,
        },
        idx: this.widgetIdx,
        step: this.step,
      });
      if (this.timeoutId) clearTimeout(this.timeoutId);
      setTimeout(updateRespond, 2000);
    },
  },
};
</script>

<style scoped>
.input-field-widget-container {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
}
.error {
  position: absolute;
  bottom: -12px;
  color: red;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  width: 100%;
  font-family: "Montserrat" sans-serif;
  line-height: 1;
}
.input-field-widget-container input,
.input-field-widget-container textarea {
  min-width: 100%;
  min-height: 100%;
  /* padding: 3px; */
  box-sizing: border-box;
  background: var(--field-background-color);
  color: var(--field-text-color);
  border: none;
  border-radius: var(--borderRadius);
  padding: var(--padding);
}
.input-field-widget-container input:placeholder-shown {
  text-overflow: ellipsis;
}
.input-field-widget-container input::placeholder,
.input-field-widget-container textarea::placeholder {
  color: var(--field-text-color);
}
.input-field-widget-container textarea::placeholder {
  line-height: var(--height);
  width: var(--width);
  height: var(--height);
  text-align: center;
}
.input-field-textarea {
  box-sizing: border-box;
  padding: 5px;
  resize: none;
  width: 100%;
  height: 100%;
  border: none;
  background: var(--field-background-color);
  color: var(--field-text-color);
}
input[type="date"]::before {
  color: var(--field-text-color);
  position: absolute;
  width: calc(100% - 10px);
  content: attr(placeholder);
}
input[type="date"] {
  text-align: center !important;
  text-align: center;
  position: relative;
  color: #ffffff00;
}
input[type="date"]:focus {
  color: var(--field-text-color);
}
input[type="date"]:focus::before {
  content: "" !important;
}
input[type="date"].with-value {
  color: var(--field-text-color);
}
input[type="date"].with-value::before {
  content: "" !important;
}
.field {
  text-align: var(--textAlign);
  font-size: var(--fontSize);
  font-weight: var(--fontWeight);
  font-style: var(--fontStyle);
  text-decoration: var(--textDecoration);
  font-family: var(--fontFamily);
}
.red-border {
  border: 1px solid red !important;
}
</style>
